import React, { useState } from "react";
import "../styles/Enquiry.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const Enquiry = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handlesubmit = async (e) => {
    e.preventDefault();
    const res = await axios.post("/api/dp/user/enquiry", {
      name,
      email,
      mobile,
      city,
      message,
    });
    console.log(res);
    if (res.data.success) {
      toast.success("enquiry submited sucessfully");
      navigate("/");
      setName("");
      setEmail("");
      setCity("");
      setMobile("");
      setMessage("");
    }
  };
  return (
    <div className="container-fluid enquiry">
      <div className="row">
        <div className="col-md-5">
          <h2>Enquier Form</h2>
          <p>
            Kindly fill the enquiry form to get our service, and we can
            understand your requirment.
          </p>
        </div>
        <div className="col-md-7 enq1">
          <div className="container-fluid ">
            <form onSubmit={handlesubmit} className="form">
              <div className=" m-4  enq2">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Name</span>
                </label>
              </div>
              <div className=" m-4  enq2">
                <input
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Mobile</span>
                </label>
              </div>
              <div className=" m-4  enq2">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Email</span>
                </label>
              </div>
              <div className=" m-4  enq2">
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">City</span>
                </label>
              </div>
              <div className=" my-5 ms-4 enq2">
                <textarea
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />

                <label for="name" className="label-name">
                  <span className="content-name">Message</span>
                </label>
              </div>
              <button type="submit" className="btn btn-info text-light">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
