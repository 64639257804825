import React, { useEffect, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import axios from "axios";

const UserDashboard = () => {
  const [enquiries, setEnquiries] = useState([]);
  const getallenquiries = async () => {
    const { data } = await axios.get("/api/dp/user/allenquiries");

    setEnquiries(data?.enquiries);
  };
  useEffect(() => {
    getallenquiries();
  }, []);
  return (
    <>
      <AdminHeader />
      <div className="container-fluid">
        <h1>User dashboard</h1>.<h4>user Enquiries</h4>
        <table className="table">
          <thead>
            <tr>
              <th>Siriel number</th>
              <th>Name</th>
              <th>email</th>
              <th>Mobile</th>
              <th>city</th>
            </tr>
          </thead>
          <tbody>
            {enquiries &&
              enquiries.map((e, index) => (
                <tr key={e._id}>
                  <td>{index + 1}</td>
                  <td>{e.name}</td>
                  <td>{e.email}</td>
                  <td>{e.mobile}</td>
                  <td>{e.city}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserDashboard;
