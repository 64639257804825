import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../styles/Accordian.css";

const Accordian = () => {
  return (
    <div className="qans mb-5">
      <h2 className="text-center my-5">
        Important question answer for clients
      </h2>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Why we choose DONSP service ?</Accordion.Header>
          <Accordion.Body className="answer">
            We think about our client, what they want to what we offers them. We
            do as required, do not mis guide to our clients.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            What quality will DONSP technology provide if we want donsp service
            ?
          </Accordion.Header>
          <Accordion.Body className="answer1">
            We try to provide as possible as best service that we can provide to
            our client. We always try to do our best for our valuable clients.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Is DONSP technology best than other service provider.
          </Accordion.Header>
          <Accordion.Body className="answer2">
            No ! We cant compare ourselve from other companies, we only aim to
            do the best that we can do.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            How we can contact to donsp technology ?
          </Accordion.Header>
          <Accordion.Body className="answer3">
            Just call on mobile number given in bottom of this web page, we
            response you immediately.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Accordian;
