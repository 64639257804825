import "../styles/Contact.css";
//import Location from "./Location";
import {
  FaLinkedinIn,
  FaTelegram,
  FaFacebookF,
  FaInstagramSquare,
  FaPinterest,
} from "react-icons/fa";

function Bottom() {
  return (
    <div className="container-fluid ">
      <div className="row">
        <hr />
        <div className="col-md-6 dip3 ">
          <h2 className="text-center cnt">CONTACT US</h2>

          <p>Contact Support timing from 9 A.M to 5 P.M</p>

          <p className="contact1 ">
            <a
              className="text-dark"
              href="tel:+919589484230"
              style={{ textAlign: "center", textDecoration: "0" }}
            >
              &#128222; :9589484230
            </a>
          </p>
          <p className="email">
            &#x1F4E7; :
            <a
              href="mailto:thebandwala@gmail.com"
              className="text-dark"
              style={{ textAlign: "center", textDecoration: "0" }}
            >
              thebandwala@gmail.com
            </a>
          </p>
          <p>pati district barwani 451661 (m.p)</p>
        </div>
        <div className="col-md-6 dip4">
          <h2 className="cnt">Connect us on social media</h2>

          <div className="col-md-4">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/thebandwala-com-5760b0226 "
                  target="_blank"
                >
                  <FaLinkedinIn
                    className="social-icon"
                    size="1.2rem"
                    color="#0a66c2"
                  />
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.instagram.com/thebandwala/"
                  target="_blank"
                >
                  <FaInstagramSquare
                    className="social-icon"
                    size="1.2rem"
                    color="#c32aa3"
                  />
                </a>
              </li>
              <br />
              <li>
                <a
                  href="https://www.facebook.com/pages/?category=your_pages&ref=bookmarks"
                  target="_blank"
                >
                  <FaFacebookF
                    className="social-icon"
                    size="1.2rem"
                    color="#1877f2"
                  />
                </a>
              </li>
              <br />
              <li>
                <a href="https://in.pinterest.com/thebandwala/" target="_blank">
                  <FaPinterest
                    className="social-icon"
                    size="1.2rem"
                    color="#bd081c"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Bottom;
