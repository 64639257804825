import React, { useState } from "react";
import Header from "./Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import "../styles/Register.css";

const Register = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleregister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/dp/user/register", {
        name,
        email,
        mobile,
        password,
      });
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header />
      <div className="container-fluid register">
        <div className="row">
          <div className="col-md-6 pp mt-3">
            <form className="form" onSubmit={handleregister}>
              <h1 className="text-center">Register here</h1>
              <div className="m-4 register1">
                <input
                  style={{ "vertical-align": "middle" }}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <label for="name" className="label-name">
                  <span className="content-name">Name</span>
                </label>
              </div>

              <div className="m-4 register1">
                <input
                  type="name"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label for="name" className="label-name">
                  <span className="content-name">Email</span>
                </label>
              </div>
              <div className="m-4 register1">
                <input
                  type="number"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  required
                />
                <label for="name" className="label-name">
                  <span className="content-name">mobile</span>
                </label>
              </div>
              <div className="m-4 register1">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label for="name" className="label-name">
                  <span className="content-name">password</span>
                </label>
              </div>
              <button type="submit" className="btn btn-info">
                Submit
              </button>
            </form>
          </div>
          <div className="col-md-6 mt-3">
            <h3>kj</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
