import React, { useRef } from "react";
import "../styles/AboutUs.css";
import { delay, motion, spring, stagger, useScroll } from "framer-motion";

const AboutUs = () => {
  const scrollref = useRef(null);

  const { scrollYProgress } = useScroll();

  //start varinat is for name of service in ul list
  const variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.5,
        when: "beforeChildren",
        viewport: { amount: 0.2 },
      },
    },
  };
  const childVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };
  //end variants for service list
  return (
    <div className="container-fluid abt2 ">
      <div className="row abt1">
        <h2 className="text-white text-center dns">OUR SERVICES</h2>
        <h6 className="text-white dns1">
          We engaged in multi services to offer to our clients, in todays days
          there become essential to go with multi aspect of modern need. Whether
          it is website development, search engine optimization,logo design or
          marketing. All are essential part of bussines developments.
        </h6>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ amount: 0.2 }}
          transition={{
            type: "spring",
            stiffness: 50,
            once: "true",
            staggerChildren: 0.5,
          }}
          className="row my-5"
        >
          <div
          /*initial={{ x: -300, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ amount: 0.2 }}
            transition={{ type: "spring", stiffness: 50, once: "true" }}*/
          >
            <h4 className="text-center text-white my-4">LOGO DESIGN</h4>
            <p className="text-white dns2 mb-5">
              We design quality logo and having variety of design for business
              purpose and other general use for your business trade mark. Just
              order and get within time, we understand value of time.
            </p>
          </div>
          <div className="col-md-6 dnsi">
            <motion.img
              className="mx-5 img1"
              src="/images/logo.jpg"
              height={"150px"}
              width={"150px"}
            ></motion.img>
            <img
              className="me-5 img2"
              src="/images/favicon.jpeg"
              height={"150px"}
              width={"150px"}
            />
            <img
              className="mr-5 img3"
              src="/images/pd.jpg"
              height={"150px"}
              width={"150px"}
            />
          </div>
        </motion.div>
        <div className="row my-5 ">
          <motion.div
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            viewport={{ root: scrollref, amount: 0.3 }}
            transition={{ type: spring, stiffness: 20 }}
          >
            <h3 className="text-center text-white my-4">Android app design</h3>
            <p className="text-white dns3 mb-5">
              In modern days android app is being used by everywhere. every
              bussines has a strong need of android app,that run on android
              phones.
            </p>
          </motion.div>
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            viewport={{ root: scrollref, amount: 0.3 }}
            transition={{ type: spring, stiffness: 20 }}
            className="col-md-6 dnsi"
          >
            <img
              className="mx-5"
              src="/images/android.jpg"
              height={"200px"}
              width={"200px"}
            />
          </motion.div>
        </div>
        <div className="row my-5">
          <div>
            <h3 className=" text-center text-white my-4">
              Search Engine Optimization
            </h3>

            <p className="text-white dns4 mb-5">
              Search engine optimization is very necessary for increasing
              traffic of website. We provide the best service at affordable
              rates. Search engine optimization in itself is a wide field that
              is requirement of todays days.
            </p>
          </div>
          <div className="col-md-6 dnsi">
            <img
              className="mx-5"
              src="/images/seo.png"
              height={"200px"}
              width={"200px"}
            />
          </div>
        </div>

        <div className="row my-5">
          <div>
            <h3 className="text-center text-white m-4">DIGITAL MARKETING</h3>
            <p className="text-white dns5 mb-5">
              Digital marketing is one of the most required action for increase
              website viewers, we are provide seamles service so that our client
              get increased business
            </p>
          </div>
          <div className="col-md-6 dnsi">
            <img
              className="mx-5"
              src="/images/campaign.png"
              height={"200px"}
              width={"200px"}
            />
          </div>
        </div>
        <div className="row my-5">
          <div>
            <h3 className=" text-center text-white my-4">
              WEB DESIGN AND DEVELOPMENT
            </h3>
            <p className="text-white dns6 mb-5">
              We provide timelly and quality service in web design and
              development. We provide maintanace of website at lowest cost, and
              host client websites on VPS and shared hosting. As a service
              provider its become important for every bussinesman to have a
              unique and best website that help to increase presence in market.
            </p>
          </div>
          <div className="col-md-6  dnsi">
            <h3 className="text-light">Our positive points</h3>
            <ul className="listing">
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                Dedicated server
              </motion.li>
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                1 year maintanance free
              </motion.li>
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                1 year server free
              </motion.li>
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                Instant update in web page{" "}
              </motion.li>
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                high speed of server
              </motion.li>
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                Customised website design
              </motion.li>
              <motion.li
                initial={{ scale: 1 }}
                whileHover={{
                  scale: 1.2,
                  transition: { duration: 2, type: "spring", stiffness: 10 },
                }}
              >
                Latest web design technology
              </motion.li>
            </ul>
            {/*<img
              className="mx-5"
              src="/images/webicon.png"
              height={"200px"}
              width={"200px"}
            />*/}
          </div>
        </div>
        <div className="row mt-5 dip2">
          <h1 className="text-center mb-5 text-white">
            We serve in following technologies and Services
          </h1>
          <div className="col-md-6 col-sm-12  ">
            <motion.ul
              variants={variants}
              initial="hidden"
              animate="visible"
              whileInView="transition"
              viewport={{ root: scrollref, amount: 0.3 }}
              className="text-white mx-5"
            >
              <motion.li variants={childVariants}>NODEJS</motion.li>
              <motion.li variants={childVariants}>REACTJS</motion.li>
              <motion.li variants={childVariants}>WORDPRESS</motion.li>
              <motion.li variants={childVariants}>PHP</motion.li>
              <motion.li variants={childVariants}>PYTHON</motion.li>
            </motion.ul>
          </div>
          <div className="col-md-6 col-sm-12 ">
            <ul className="text-white mx-5">
              <li>ONLINE MARKETING</li>
              <li>APP SEO</li>
              <li>BPO</li>
              <li>LOGO DESIGN</li>
              <li>CONTENT WRITTING</li>
              <li>ANDROID APP DESIGN</li>
            </ul>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          width={"1650"}
        >
          <path
            fill="#4E5180"
            fill-opacity="1"
            d="M0,160L48,149.3C96,139,192,117,288,122.7C384,128,480,160,576,186.7C672,213,768,235,864,202.7C960,171,1056,85,1152,53.3C1248,21,1344,43,1392,53.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default AboutUs;
