import React from "react";
import Header from "./Header";
import Contact from "./Contact";
import Slider from "./slider/Slider";
import AboutUs from "./AboutUs";
import Accordian from "./Accordian";
import Footer from "./Footer";
import Enquiry from "./Enquiry";

const Home = () => {
  return (
    <div>
      <Header />
      <Slider />
      <AboutUs />
      <Accordian />
      <Enquiry />
      <Contact />

      <Footer />
    </div>
  );
};

export default Home;
