import React, { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/authContext";
import "../styles/Login.css";

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    const res = await axios.post("/api/dp/user/login", { email, password });
    if (res && res.data.success) {
      toast.success("logined user sucesfuly");
      setAuth({
        ...auth,
        user: res.data.user,
        token: res.data.token,
      });
      localStorage.setItem("auth", JSON.stringify(res.data));
      navigate("/userdashboard");
    }
  };

  useEffect(() => {
    const loggedinuser = localStorage.getItem("auth");
    if (loggedinuser) {
      setUser(loggedinuser);
      navigate("/userdashboard");
    }
  }, [auth?.token]);
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container-fluid login">
          <div className="row">
            <div className="col-md-7 login1">
              <div className="container-fluid ">
                <h3>Login</h3>
                <form onSubmit={handleLogin} className="form">
                  <div className=" m-4  login2">
                    <input
                      type="name"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <label for="name" className="label-name">
                      <span className="content-name">Email</span>
                    </label>
                  </div>
                  <div className=" m-4  login2">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />

                    <label for="name" className="label-name">
                      <span className="content-name">Password</span>
                    </label>
                  </div>

                  <button type="submit" className="text-light">
                    Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
