import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Slider.css";

const Slider = () => {
  return (
    <div className=" pd12">
      <Carousel fade>
        <Carousel.Item>
          <img src="/images/pic.jpg" />
          <Carousel.Caption className="cwr ">
            <h2 className="car1 ">Watch your dreams becoming reality</h2>
            <p className="sl">
              Imagine your dream and go for seing them true,there is no limit of
              sky, its you who can do it.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src="/images/pic1.jpg" />
          <Carousel.Caption className="cwr">
            <h3 className="car1">Best marketing tools </h3>
            <p className="sl">
              Approach your bussines to new customers by online marketing that
              will increase audience for your bussines.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src="/images/tech.jpg" />
          <Carousel.Caption className="cwr">
            <h3 className="car1">Join hands with us </h3>
            <p className="sl">
              Donsp technology is working in the field of new technologies,
              everyday technology is going to be changed.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Slider;
