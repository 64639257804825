import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div className=" footer">
      <p className="text-center ">
        all right reserved by &#169;DONSP technology
      </p>
    </div>
  );
};

export default Footer;
