import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { toast } from "react-hot-toast";

const AdminHeader = () => {
  const [auth, setAuth] = useAuth();

  const handlelogout = () => {
    setAuth({
      ...auth,
      user: "",
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("logout succesfuly");
  };
  return (
    <nav className="navbar navbar-expand-lg  ">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <Link to="/" className="navbar-brand ">
            <b className="donsp">DONSP</b>
            <i className="donspsub">Technology</i>
          </Link>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink to="" className="nav-link ">
                Profile
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink onClick={handlelogout} to="/" className="nav-link ">
                Logout
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="" className="nav-link ">
                mesager
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default AdminHeader;
